.cartoonPlayer {
  height: 100%;
  background-color: unset;
}
.cartoonPlayer img {
  -webkit-filter: brightness(30%);
  filter: brightness(30%);
}
.cartoonPlayer .cartoonPlayer-login {
  position: absolute;
  top: 50%;
  left: 3.1vw;
}
.w-100 {
  width: 100%;
}
.text-red {
  color: #ee4325;
}
.border-bottom-grey {
  border-bottom-color: #767e89;
  border-bottom-style: solid;
  border-bottom-width: 0.12rem;
}
.block-video {
  visibility: hidden;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.block-video .quality-selector {
  font-size: 15px;
  color: #808080;
  text-align: center;
  height: 35.5px;
}
.block-video .quality-selector .btn-group-quality {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  margin-top: 15px;
}
.block-video .quality-selector .btn.btn-video-quality {
  font-size: 16px;
}
.block-video .quality-selector .quality-selector-caption {
  padding-right: 10px;
}
.block-video .quality-selector .quality-selector-caption {
  float: left;
  color: #343434;
}
.block-video .quality-selector .btn-group {
  margin: 0 auto;
  text-align: center;
  display: inline-block;
}
.block-video .quality-selector .btn-video-quality {
  padding: 2px 0 0 0;
  font-size: 15px;
  margin: 0 0 0 26px !important;
  line-height: 16px;
}
.block-video .quality-selector .btn-video-quality.active {
  border-bottom: 1px solid #343434;
  color: #343434;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.btn.active.focus {
  outline: none;
}
button,
[type="tel"] {
  outline: none;
  outline-width: 0;
}
button:focus,
[type="tel"]:focus {
  outline: none;
  outline-width: 0;
}
.verifregister {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}
.verifregister p {
  margin: 0;
  line-height: initial;
}
.verifregister h2 {
  margin: 15px 0;
  font-size: 25px;
}
.login_main .link-red {
  margin-top: 40px;
}
.audiobook_player {
  display: block;
  font-size: 22px;
}
.audiobook_player .audiobook_player_page {
  min-height: 100vh;
  background-color: #EAEAEA;
}
.audiobook_player .audiobook_player_page .block_player_img {
  height: 450px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.audiobook_player .audiobook_player_page .block_player_img .bg_img_blur {
  position: absolute;
  height: 450px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.audiobook_player .audiobook_player_page .block_player_img .block_player_img_small {
  position: relative;
  z-index: 2;
}
.audiobook_player .audiobook_player_page .player {
  margin: 0 20px;
}
.audiobook_player .audiobook_player_page .player .line-time-val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 30px;
}
.audiobook_player .audiobook_player_page .player .line-time-val .left-time,
.audiobook_player .audiobook_player_page .player .line-time-val .right-time {
  font-size: 22px;
  color: #666;
}
.audiobook_player .audiobook_player_page .player_time {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 30px;
}
.audiobook_player .audiobook_player_page .player_time .progress-holder {
  position: relative;
  width: 100%;
  background-color: #666;
  height: 9px;
}
.audiobook_player .audiobook_player_page .player_time .progress-bar {
  background-color: #666;
  position: relative;
  width: 100%;
  height: 9px;
}
.audiobook_player .audiobook_player_page .player_time .line {
  position: absolute;
  top: 0;
  left: 0;
  height: 9px;
  background-color: #e60b00;
  text-align: right;
}
.audiobook_player .audiobook_player_page .player_time .line:before {
  content: '';
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  border-radius: 50%;
  background-color: #e60000;
  cursor: pointer;
  margin: -16px -3px;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"] {
  width: 400px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 6px;
  border: none;
  border-radius: 0;
  background: #666;
  outline: none;
  position: relative;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-track {
  border: inherit;
  color: transparent;
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-fill-lower,
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-fill-upper {
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-tooltip {
  display: none;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #e60000;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-moz-range-thumb {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #e60000;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  background-color: #e60000;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_btn {
  margin: 9px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.audiobook_player .audiobook_player_page .player_btn button {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}
.audiobook_player .audiobook_player_page .player_btn_left,
.audiobook_player .audiobook_player_page .player_btn_right,
.audiobook_player .audiobook_player_page .player_btn_play {
  border-color: transparent;
  background-color: transparent;
  position: relative;
  margin: 0 -21px;
}
.audiobook_player .audiobook_player_page .player_btn_left i,
.audiobook_player .audiobook_player_page .player_btn_right i,
.audiobook_player .audiobook_player_page .player_btn_play i {
  width: 66px;
  height: 66px;
  position: absolute;
  top: -2px;
  left: -2px;
}
.audiobook_player .audiobook_player_page .player_btn_left i {
  background: url("/images/newimg/play_left.svg") no-repeat center center;
  background-size: 45px;
}
.audiobook_player .audiobook_player_page .player_btn_left i:hover {
  background: url("/images/newimg/play_left-hover.svg") no-repeat center center;
  background-size: 45px;
}
.audiobook_player .audiobook_player_page .player_btn_right i {
  background: url("/images/newimg/play_right.svg") no-repeat center center;
  background-size: 45px;
}
.audiobook_player .audiobook_player_page .player_btn_right i:hover {
  background: url("/images/newimg/play_right-hover.svg") no-repeat center center;
  background-size: 45px;
}
.audiobook_player .audiobook_player_page .player_btn_play i {
  background: url("/images/newimg/play.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn_play i:hover {
  background: url("/images/newimg/play-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
  background: url("/images/newimg/play.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
  background: url("/images/newimg/play-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i {
  background: url("/images/newimg/play_pause.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i:hover {
  background: url("/images/newimg/play_pause-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active:disabled {
  background: url("/images/newimg/play_pause-disabled.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player .txt_player_name,
.audiobook_player .audiobook_player_page .player .txt_player_author {
  display: none;
}
.audiobook_player .audiobook_player_page .player_list {
  margin: 35px auto 30px;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
}
.audiobook_player .audiobook_player_page .player_list ul {
  width: 100%;
}
.audiobook_player .audiobook_player_page .player_list ul li {
  height: 125px;
}
.audiobook_player .audiobook_player_page .player_list ul li a {
  color: #333;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "MTSSans";
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 125px;
  padding: 0 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.audiobook_player .audiobook_player_page .player_list ul li a span:first-child {
  width: 380px;
  line-height: 40px;
}
.audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player {
  background: url("/images/newimg/play_list_icon.svg") no-repeat center center;
  width: 35px;
  height: 35px;
  position: relative;
  top: 0;
  left: 10px;
}
.audiobook_player .audiobook_player_page .player_list ul li a:active,
.audiobook_player .audiobook_player_page .player_list ul li a:hover {
  background-color: #F6F6F6;
  font-family: "MTSSans";
}
.audiobook_player .audiobook_player_page .player_list ul li a:active .icon_list_player,
.audiobook_player .audiobook_player_page .player_list ul li a:hover .icon_list_player {
  background: url("/images/newimg/play_list_icon-hover.svg") no-repeat center center;
  width: 35px;
  height: 35px;
  left: 10px;
}
.audiobook_player .audiobook_player_page .player_list ul li.active a {
  background-color: #F6F6F6;
  font-family: "MTSSans";
}
.audiobook_player .audiobook_player_page .player_list ul li.active a .icon_list_player {
  background: url("/images/newimg/play_list_icon-hover.svg") no-repeat center center;
  width: 35px;
  height: 35px;
  left: 10px;
}
.audiobook_player .audiobook_player_page .player_list ul li + li {
  border-top: 1px solid #ccc;
}
@media screen and (min-width: 768px) {
  /*----------------------------------------player-------------------------------------------*/
  .audiobook_player {
    top: 115px;
  }
  .audiobook_player .header.header_player {
    display: none;
  }
  .audiobook_player .audiobook_player_page {
    padding: 14px 20px;
  }
  .audiobook_player .audiobook_player_page .block_player_img {
    height: 100%;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    width: 35%;
    float: left;
  }
  .audiobook_player .audiobook_player_page .block_player_img .bg_img_blur {
    display: none;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small {
    width: 250px;
    height: 228px;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small img {
    width: 100%;
    height: 100%;
  }
  .audiobook_player .audiobook_player_page .player_btn button {
    width: 67px;
    height: 67px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i {
    width: 67px;
    height: 67px;
    background-size: 35px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i:hover {
    background-size: 35px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i:hover,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player {
    margin-right: 0;
    display: inline-block;
    width: 62.2%;
  }
  .audiobook_player .audiobook_player_page .player .txt_player_name,
  .audiobook_player .audiobook_player_page .player .txt_player_author {
    display: block;
    text-align: center;
  }
  .audiobook_player .audiobook_player_page .player .txt_player_name {
    margin: 17px 0 14px;
    font: 34px/36px "MTSSans";
  }
  .audiobook_player .audiobook_player_page .player .txt_player_author {
    font: 20px/24px "MTSSans";
  }
  .audiobook_player .audiobook_player_page .player .player_time {
    height: 57px;
    margin: 0 17px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .left-time,
  .audiobook_player .audiobook_player_page .player .player_time .right-time {
    font-size: 18px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .progress-holder {
    height: 4px;
    margin: 25px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .line {
    height: 4px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .line::before {
    width: 27px;
    height: 27px;
    margin: -13px -3px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .progress-bar {
    height: 4px;
  }
  .audiobook_player .audiobook_player_page .player_list {
    margin: 22px auto 30px;
    width: 100%;
  }
  .audiobook_player .audiobook_player_page .player_list ul li {
    height: 84px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a {
    font-size: 18px;
    line-height: 28px;
    height: 84px;
    padding: 0 24px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a span:first-child {
    width: 84%;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player {
    background-size: 18px;
    width: 28px;
    height: 24px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a:hover .icon_list_player {
    background-size: 18px;
    width: 28px;
    height: 24px;
    left: 10px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li.active a .icon_list_player {
    background-size: 58%;
    width: 28px;
    height: 23px;
    left: 10px;
  }
}
.block_loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8.1vh;
}
@media (min-width: 768px) {
  .cartoonPlayer-play.logout {
    display: none;
  }
}
@media (max-width: 768px) {
  .mediaGridContainer-more {
    font-size: 2.3vh;
    padding-top: .6em;
    padding-bottom: .6em;
    display: initial;
    margin-bottom: 5vh;
  }
}
.header-login {
  white-space: nowrap;
  padding-left: 1em;
  padding-right: 1em;
}
